import { ContextProvider, createContext } from '@lit/context';
import { ErrorDialogController } from '../controllers/error-dialog-controller';
export { ErrorDialogController };

export const errorDialogContext = createContext<ErrorDialogController>('errorDialog');

export const createErrorDialogProvider = (initialValue: ErrorDialogController) =>
    new ContextProvider(document.body, {
        context: errorDialogContext,
        initialValue,
    });
