import { bindable, bindingMode } from 'aurelia-framework';
export class SortableHeader {
    @bindable({ defaultBindingMode: bindingMode.oneTime }) field: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    firstSortOrderValue: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    firstSortOrderField: string;

    @bindable sort;
    order: Order = null;
    icon: Icons = Icons.Unsorted;

    attached() {
        this.icon = Icons.Unsorted;
        if (this.field !== this.firstSortOrderField) {
            this.order = this.firstSortOrderValue === Order.Desc.toString() ? Order.Asc : Order.Desc;
            return;
        }

        this.order = this.firstSortOrderValue === Order.Desc.toString() ? Order.Desc : Order.Asc;
        if (this.order === Order.Asc) {
            this.icon = Icons.SortUp;
        } else if (this.order === Order.Desc) {
            this.icon = Icons.SortDown;
        }
    }

    async onSort() {
        if (this.order == Order.Desc) {
            this.order = Order.Asc;
            this.icon = Icons.SortUp;
        } else {
            this.order = Order.Desc;
            this.icon = Icons.SortDown;
        }
        await this.sort({ field: this.field, order: this.order });
    }

    firstSortOrderFieldChanged(value: string) {
        if (this.field !== value) {
            this.icon = Icons.Unsorted;
        }
    }
}

enum Order {
    Asc = 'asc',
    Desc = 'desc',
}

enum Icons {
    Unsorted = 'filter-left',
    SortDown = 'sort-down',
    SortUp = 'sort-up',
}
