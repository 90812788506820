import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styles } from 'pli/styles';
import { GetTransactionList_TransactionItem } from 'tms-client';
import '../../../pli/pli-text';
import '../../property-view/poc/property-view';

@customElement('transaction-dialog-section')
class TranasctionDialogSection extends LitElement {
    static styles = [styles.grid];
    @property()
    title: string;
    render() {
        return html`<div class="grid-vertical gap-05">
            <pli-text as="p"><strong>${this.title}</strong></pli-text>
            <slot></slot>
        </div>`;
    }
}

@customElement('transaction-dialog-content')
class TransactionDialogContent extends LitElement {
    @property({ type: Object })
    item: GetTransactionList_TransactionItem;

    static styles = [css``, styles.padding, styles.divider, styles.grid, styles.flex];

    render() {
        return html`<div class="divider-y">
            <div class="px-1-5 pb-1-5 grid-vertical gap-1">
                <pli-text variant="h5">Transaction details</pli-text>
                <div class="grid gap-1">
                    <transaction-dialog-section class="col-span-6" title="Id">
                        <pli-text>${this.item.id}</pli-text>
                    </transaction-dialog-section>
                    <transaction-dialog-section class="col-span-6" title="Booked on">
                        <pli-text>${this.item.booked}</pli-text>
                    </transaction-dialog-section>
                    <transaction-dialog-section class="col-span-6" title="Customer">
                        <pli-text>${this.item.customer.name}</pli-text>
                    </transaction-dialog-section>
                    <transaction-dialog-section class="col-span-6" title="Amount">
                        <pli-text>${this.item.amount.value}</pli-text>
                    </transaction-dialog-section>
                    <transaction-dialog-section class="col-span-6" title="Currency">
                        <pli-text>${this.item.amount.currency}</pli-text>
                    </transaction-dialog-section>
                </div>
            </div>
            <div class="p-1-5 grid-vertical gap-1">
                <pli-text variant="h5">Transaction properties</pli-text>
                <div class="grid gap-1">
                    ${this.item.properties?.map(
                        (property) => html`
                            <transaction-dialog-section class="col-span-6" title="${property.name}">
                                <property-view .prop="${property}"></property-view>
                            </transaction-dialog-section>
                        `,
                    )}
                </div>
            </div>
            <div class="p-1-5 grid-vertical gap-1">
                <pli-text variant="h5">Transaction party properties</pli-text>
                <div class="grid gap-1">
                    <div class="col-span-12">
                        <transaction-dialog-section class="col-span-6" title="CustomerId">
                            ${this.item.transactionParty.customerId || 'N/A'}
                        </transaction-dialog-section>
                    </div>
                    ${this.item.transactionParty?.properties?.map(
                        (property) => html`
                            <transaction-dialog-section class="col-span-6" title="${property.name}">
                                <property-view .prop="${property}"></property-view>
                            </transaction-dialog-section>
                        `,
                    )}
                </div>
            </div>
        </div>`;
    }
}
