import { ContextProvider, createContext } from '@lit/context';
import { ToastController } from 'controllers/toast-controller';

export const toastServiceContext = createContext<ToastController>('toast');

export const createToastServiceProvider = (initialValue: ToastController) =>
    new ContextProvider(document.body, {
        context: toastServiceContext,
        initialValue,
    });
