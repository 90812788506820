import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../pli/pli-dialog';
import '../../pli/pli-radio-item-group';
import { PliRadioItemGroupUpdatedEvent } from '../../pli/pli-radio-item-group';
import { when } from 'lit/directives/when.js';
import { Task } from '@lit/task';
import { getUserList, GetUserList_ItemResult, GetUserListResponse } from 'tms-client';
import { consume } from '@lit/context';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';
import '../../pli/pli-checkbox';
import { PliCheckboxUpdateEvent } from '../../pli/pli-checkbox';
import '../../pli/pli-user-bubble';
import { styles } from 'pli/styles';
import { CurrentUser, currentUserContext } from 'context/current-user-context';
import { UserModel } from 'user-model';

type AssignTo = 'me' | 'others';

@customElement('assign-dialog')
export class AssignAlertDialog extends LitElement {
    static styles = [styles.flex, styles.grid, styles.divider, styles.padding];
    radioItems: { text: string; value: AssignTo }[] = [
        { text: 'Assign to me', value: 'me' },
        { text: 'Assign to others', value: 'others' },
    ];

    @property()
    type: 'case' | 'alert' = 'alert';

    @consume({ context: errorDialogContext })
    errorDialog: ErrorDialogController;

    @consume({ context: currentUserContext })
    currentUser: CurrentUser;

    @state()
    selected: AssignTo = 'me';

    @state()
    selectedOtherUsers: GetUserList_ItemResult['userId'][] = [];

    data: GetUserListResponse = null;

    _currentUserProfile = new UserModel();

    async connectedCallback() {
        super.connectedCallback();
        this._currentUserProfile = await this.currentUser.get();
    }

    async handleChange(event: PliRadioItemGroupUpdatedEvent) {
        const { selected } = event.detail;
        this.selected = selected as AssignTo;
    }

    _task = new Task(this, {
        task: async ([]) => {
            const { data, error } = await getUserList();

            if (error) {
                return this.errorDialog.showError({ title: 'Could not get users' });
            }

            this.data = data;
        },
        args: () => [],
    });

    handleAssignOtherUserChange(event: PliCheckboxUpdateEvent) {
        const { checked, value } = event.detail;
        if (checked) {
            this.selectedOtherUsers = [...this.selectedOtherUsers, value];
        } else {
            this.selectedOtherUsers = [...this.selectedOtherUsers.filter((i) => i !== value)];
        }
    }

    async handlePrimaryDismiss() {
        const _event: AssignDialogEvent = new CustomEvent('assign', {
            composed: true,
            detail: { users: this.selectedUsers },
        });

        this.dispatchEvent(_event);
    }

    get selectedUsers() {
        if (this.selected === 'me') {
            return [this._currentUserProfile];
        } else {
            return [...this.data.list.filter((user) => this.isOtherUserSelected(user.userId))];
        }
    }

    isOtherUserSelected(id: string) {
        return this.selectedOtherUsers.includes(id);
    }

    render() {
        return html`<pli-dialog @primary-dismiss="${this.handlePrimaryDismiss}">
            <pli-dialog-open-button slot="open-button">Assign</pli-dialog-open-button>
            <pli-dialog-content>
                <pli-icon-box slot="icon" name="person-plus-fill"></pli-icon-box>
                <pli-text variant="h3">Assign ${this.type} </pli-text>
                <pli-text>Assign this ${this.type} to yourself or your colleagues. </pli-text>
                <pli-radio-item-group
                    .items="${this.radioItems}"
                    @change="${this.handleChange}"
                    .selected="${this.selected}"
                ></pli-radio-item-group>
                ${when(
                    this.selected === 'others',
                    () =>
                        html`<div class="divider-y">
                            ${this.data.list.map(
                                (user) =>
                                    html`<div class="pl-1-5">
                                        <pli-checkbox
                                            .checked="${this.isOtherUserSelected(user.userId)}"
                                            @change="${this.handleAssignOtherUserChange}"
                                            .value="${user.userId}"
                                            ><div class="flex items-center gap-05 pl-2">
                                                <pli-user-bubble
                                                    firstName="${user.firstName}"
                                                    lastName="${user.lastName}"
                                                    userId="${user.userId}"
                                                ></pli-user-bubble>
                                                ${user.fullName}
                                            </div></pli-checkbox
                                        >
                                    </div>`,
                            )}
                        </div>`,
                )}
            </pli-dialog-content>
            <pli-dialog-close-button slot="secondary-button">Cancel</pli-dialog-close-button>
            <pli-dialog-primary-button slot="primary-button">Assign</pli-dialog-primary-button>
        </pli-dialog>`;
    }
}

export type AssignDialogEvent = CustomEvent<{ users: GetUserList_ItemResult[] }>;
