// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-footer {
  display: flex;
  align-items: center;
}
.table-footer .left {
  text-align: right;
  padding-right: 0.5rem;
}
.table-footer .left, .table-footer::after {
  content: "";
  flex: 1;
}
.table-footer ul {
  margin-bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/pager/pager.scss","webpack://./src/css/_sizes.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EAEA,mBAAA;AAFJ;AAII;EACI,iBAAA;EACA,qBCHC;ADCT;AAKI;EAEI,WAAA;EACA,OAAA;AAJR;AAOI;EACI,kBAAA;AALR","sourcesContent":["@import '/src/css/sizes';\n\n.table-footer {\n    display: flex;\n\n    align-items: center;\n\n    .left {\n        text-align: right;\n        padding-right: $size-2;\n    }\n\n    .left,\n    &::after {\n        content: '';\n        flex: 1;\n    }\n\n    ul {\n        margin-bottom: 0px;\n    }\n}\n","$font-size-root: 14px;\n\n$size-base: 1rem; // 14\n\n$size-0: 0.125rem; // 1,75\n$size-1: 0.25rem; // 3,5\n$size-2: 0.5rem; // 7\n$size-3: 0.75rem; // 10,5\n$size-4: 1.2rem; // 16.8\n$size-5: 1.5rem; // 21\n$size-6: 2rem; // 28\n$size-7: 2.5rem; //35\n$size-8: 3.5rem; // 49\n$size-9: 5rem; // 70\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
