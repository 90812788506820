// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `risk-level .badge.low {
  background-color: #a3cfbb;
  color: #0f5132;
}
risk-level .badge.medium {
  background-color: #ffe69c;
  color: #664d03;
}
risk-level .badge.high {
  background-color: #ea868f;
  color: #58151c;
}
risk-level .badge.critical {
  background-color: #842029;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/risk-level/risk-level.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;EACA,cAAA;AAAR;AAGI;EACI,yBAAA;EACA,cAAA;AADR;AAII;EACI,yBAAA;EACA,cAAA;AAFR;AAKI;EACI,yBAAA;EACA,cAAA;AAHR","sourcesContent":["risk-level {\n    .badge.low {\n        background-color: #a3cfbb;\n        color: #0f5132;\n    }\n\n    .badge.medium {\n        background-color: #ffe69c;\n        color: #664d03;\n    }\n\n    .badge.high {\n        background-color: #ea868f;\n        color: #58151c;\n    }\n\n    .badge.critical {\n        background-color: #842029;\n        color: #ffffff;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
