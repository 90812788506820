import { ContextProvider, createContext } from '@lit/context';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { CurrentUser } from './current-user-context';
import { ErrorService } from 'error/error-service';
import { DialogService } from 'aurelia-dialog';

export type AppContext = {
    router: Router;
    httpClient: HttpClient;
    currentUser: CurrentUser;
    errorService: ErrorService;
    dialogService: DialogService;
};

export const appContext = createContext<AppContext>('app-context');
export const createAppContextProvider = (initialValue: AppContext) =>
    new ContextProvider(document.body, {
        context: appContext,
        initialValue: initialValue,
    });
