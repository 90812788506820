import { ContextProvider, createContext } from '@lit/context';
import { CurrentUser } from 'components/current-user/current-user';
export { CurrentUser } from 'components/current-user/current-user';

export const currentUserContext = createContext<CurrentUser>('current-user');
export const createCurrentUserContextProvider = (currentUser: CurrentUser) =>
    new ContextProvider(document.body, {
        context: currentUserContext,
        initialValue: currentUser,
    });
