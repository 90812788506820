export class Country {
    public value: string;
    public display: string;
}

export var Countries: Country[] = [
    <Country>{ value: '-', display: 'Unknown' },
    <Country>{ value: 'AF', display: 'Afghanistan' },
    <Country>{ value: 'AL', display: 'Albania' },
    <Country>{ value: 'DZ', display: 'Algeria' },
    <Country>{ value: 'AS', display: 'American Samoa' },
    <Country>{ value: 'AD', display: 'Andorra' },
    <Country>{ value: 'AO', display: 'Angola' },
    <Country>{ value: 'AI', display: 'Anguilla' },
    <Country>{ value: 'AQ', display: 'Antarctica' },
    <Country>{ value: 'AG', display: 'Antigua and Barbuda' },
    <Country>{ value: 'AR', display: 'Argentina' },
    <Country>{ value: 'AM', display: 'Armenia' },
    <Country>{ value: 'AW', display: 'Aruba' },
    <Country>{ value: 'AU', display: 'Australia' },
    <Country>{ value: 'AT', display: 'Austria' },
    <Country>{ value: 'AZ', display: 'Azerbaijan' },
    <Country>{ value: 'BS', display: 'Bahamas (the)' },
    <Country>{ value: 'BH', display: 'Bahrain' },
    <Country>{ value: 'BD', display: 'Bangladesh' },
    <Country>{ value: 'BB', display: 'Barbados' },
    <Country>{ value: 'BY', display: 'Belarus' },
    <Country>{ value: 'BE', display: 'Belgium' },
    <Country>{ value: 'BZ', display: 'Belize' },
    <Country>{ value: 'BJ', display: 'Benin' },
    <Country>{ value: 'BM', display: 'Bermuda' },
    <Country>{ value: 'BT', display: 'Bhutan' },
    <Country>{ value: 'BO', display: 'Bolivia (Plurinational State of)' },
    <Country>{ value: 'BQ', display: 'Bonaire, Sint Eustatius and Saba' },
    <Country>{ value: 'BA', display: 'Bosnia and Herzegovina' },
    <Country>{ value: 'BW', display: 'Botswana' },
    <Country>{ value: 'BV', display: 'Bouvet Island' },
    <Country>{ value: 'BR', display: 'Brazil' },
    <Country>{ value: 'IO', display: 'British Indian Ocean Territory (the)' },
    <Country>{ value: 'BN', display: 'Brunei Darussalam' },
    <Country>{ value: 'BG', display: 'Bulgaria' },
    <Country>{ value: 'BF', display: 'Burkina Faso' },
    <Country>{ value: 'BI', display: 'Burundi' },
    <Country>{ value: 'CV', display: 'Cabo Verde' },
    <Country>{ value: 'KH', display: 'Cambodia' },
    <Country>{ value: 'CM', display: 'Cameroon' },
    <Country>{ value: 'CA', display: 'Canada' },
    <Country>{ value: 'KY', display: 'Cayman Islands (the)' },
    <Country>{ value: 'CF', display: 'Central African Republic (the)' },
    <Country>{ value: 'TD', display: 'Chad' },
    <Country>{ value: 'CL', display: 'Chile' },
    <Country>{ value: 'CN', display: 'China' },
    <Country>{ value: 'CX', display: 'Christmas Island' },
    <Country>{ value: 'CC', display: 'Cocos (Keeling) Islands (the)' },
    <Country>{ value: 'CO', display: 'Colombia' },
    <Country>{ value: 'KM', display: 'Comoros (the)' },
    <Country>{ value: 'CD', display: 'Congo (the Democratic Republic of the)' },
    <Country>{ value: 'CG', display: 'Congo (the)' },
    <Country>{ value: 'CK', display: 'Cook Islands (the)' },
    <Country>{ value: 'CR', display: 'Costa Rica' },
    <Country>{ value: 'HR', display: 'Croatia' },
    <Country>{ value: 'CU', display: 'Cuba' },
    <Country>{ value: 'CW', display: 'Curaçao' },
    <Country>{ value: 'CY', display: 'Cyprus' },
    <Country>{ value: 'CZ', display: 'Czechia' },
    <Country>{ value: 'DK', display: 'Denmark' },
    <Country>{ value: 'DM', display: 'Dominica' },
    <Country>{ value: 'DO', display: 'Dominican Republic (the)' },
    <Country>{ value: 'EC', display: 'Ecuador' },
    <Country>{ value: 'EG', display: 'Egypt' },
    <Country>{ value: 'SV', display: 'El Salvador' },
    <Country>{ value: 'GQ', display: 'Equatorial Guinea' },
    <Country>{ value: 'ER', display: 'Eritrea' },
    <Country>{ value: 'EE', display: 'Estonia' },
    <Country>{ value: 'SZ', display: 'Eswatini' },
    <Country>{ value: 'ET', display: 'Ethiopia' },
    <Country>{ value: 'FK', display: 'Falkland Islands (the) [Malvinas]' },
    <Country>{ value: 'FO', display: 'Faroe Islands (the)' },
    <Country>{ value: 'FJ', display: 'Fiji' },
    <Country>{ value: 'FI', display: 'Finland' },
    <Country>{ value: 'FR', display: 'France' },
    <Country>{ value: 'GF', display: 'French Guiana' },
    <Country>{ value: 'PF', display: 'French Polynesia' },
    <Country>{ value: 'TF', display: 'French Southern Territories (the)' },
    <Country>{ value: 'GA', display: 'Gabon' },
    <Country>{ value: 'GM', display: 'Gambia (the)' },
    <Country>{ value: 'GE', display: 'Georgia' },
    <Country>{ value: 'DE', display: 'Germany' },
    <Country>{ value: 'GH', display: 'Ghana' },
    <Country>{ value: 'GI', display: 'Gibraltar' },
    <Country>{ value: 'GR', display: 'Greece' },
    <Country>{ value: 'GL', display: 'Greenland' },
    <Country>{ value: 'GD', display: 'Grenada' },
    <Country>{ value: 'GP', display: 'Guadeloupe' },
    <Country>{ value: 'GU', display: 'Guam' },
    <Country>{ value: 'GT', display: 'Guatemala' },
    <Country>{ value: 'GG', display: 'Guernsey' },
    <Country>{ value: 'GN', display: 'Guinea' },
    <Country>{ value: 'GW', display: 'Guinea-Bissau' },
    <Country>{ value: 'GY', display: 'Guyana' },
    <Country>{ value: 'HT', display: 'Haiti' },
    <Country>{ value: 'HM', display: 'Heard Island and McDonald Islands' },
    <Country>{ value: 'VA', display: 'Holy See (the)' },
    <Country>{ value: 'HN', display: 'Honduras' },
    <Country>{ value: 'HK', display: 'Hong Kong' },
    <Country>{ value: 'HU', display: 'Hungary' },
    <Country>{ value: 'IS', display: 'Iceland' },
    <Country>{ value: 'IN', display: 'India' },
    <Country>{ value: 'ID', display: 'Indonesia' },
    <Country>{ value: 'IR', display: 'Iran (Islamic Republic of)' },
    <Country>{ value: 'IQ', display: 'Iraq' },
    <Country>{ value: 'IE', display: 'Ireland' },
    <Country>{ value: 'IM', display: 'Isle of Man' },
    <Country>{ value: 'IL', display: 'Israel' },
    <Country>{ value: 'IT', display: 'Italy' },
    <Country>{ value: 'JM', display: 'Jamaica' },
    <Country>{ value: 'JP', display: 'Japan' },
    <Country>{ value: 'JE', display: 'Jersey' },
    <Country>{ value: 'JO', display: 'Jordan' },
    <Country>{ value: 'KZ', display: 'Kazakhstan' },
    <Country>{ value: 'KE', display: 'Kenya' },
    <Country>{ value: 'KI', display: 'Kiribati' },
    <Country>{ value: 'LS', display: 'Lesotho' },
    <Country>{ value: 'LR', display: 'Liberia' },
    <Country>{ value: 'LY', display: 'Libya' },
    <Country>{ value: 'LI', display: 'Liechtenstein' },
    <Country>{ value: 'LT', display: 'Lithuania' },
    <Country>{ value: 'LU', display: 'Luxembourg' },
    <Country>{ value: 'MO', display: 'Macao' },
    <Country>{ value: 'MG', display: 'Madagascar' },
    <Country>{ value: 'MW', display: 'Malawi' },
    <Country>{ value: 'MY', display: 'Malaysia' },
    <Country>{ value: 'MV', display: 'Maldives' },
    <Country>{ value: 'ML', display: 'Mali' },
    <Country>{ value: 'MT', display: 'Malta' },
    <Country>{ value: 'MH', display: 'Marshall Islands (the)' },
    <Country>{ value: 'MQ', display: 'Martinique' },
    <Country>{ value: 'MR', display: 'Mauritania' },
    <Country>{ value: 'MU', display: 'Mauritius' },
    <Country>{ value: 'YT', display: 'Mayotte' },
    <Country>{ value: 'MX', display: 'Mexico' },
    <Country>{ value: 'FM', display: 'Micronesia (Federated States of)' },
    <Country>{ value: 'MD', display: 'Moldova (the Republic of)' },
    <Country>{ value: 'MC', display: 'Monaco' },
    <Country>{ value: 'MN', display: 'Mongolia' },
    <Country>{ value: 'ME', display: 'Montenegro' },
    <Country>{ value: 'MS', display: 'Montserrat' },
    <Country>{ value: 'MA', display: 'Morocco' },
    <Country>{ value: 'MZ', display: 'Mozambique' },
    <Country>{ value: 'MM', display: 'Myanmar' },
    <Country>{ value: 'NA', display: 'Namibia' },
    <Country>{ value: 'NR', display: 'Nauru' },
    <Country>{ value: 'NP', display: 'Nepal' },
    <Country>{ value: 'NL', display: 'Netherlands (the)' },
    <Country>{ value: 'NC', display: 'New Caledonia' },
    <Country>{ value: 'NZ', display: 'New Zealand' },
    <Country>{ value: 'NI', display: 'Nicaragua' },
    <Country>{ value: 'NE', display: 'Niger (the)' },
    <Country>{ value: 'NG', display: 'Nigeria' },
    <Country>{ value: 'NU', display: 'Niue' },
    <Country>{ value: 'NF', display: 'Norfolk Island' },
    <Country>{ value: 'MP', display: 'Northern Mariana Islands (the)' },
    <Country>{ value: 'NO', display: 'Norway' },
    <Country>{ value: 'OM', display: 'Oman' },
    <Country>{ value: 'PK', display: 'Pakistan' },
    <Country>{ value: 'PW', display: 'Palau' },
    <Country>{ value: 'PS', display: 'Palestine, State of' },
    <Country>{ value: 'PA', display: 'Panama' },
    <Country>{ value: 'PG', display: 'Papua New Guinea' },
    <Country>{ value: 'PY', display: 'Paraguay' },
    <Country>{ value: 'PE', display: 'Peru' },
    <Country>{ value: 'PH', display: 'Philippines (the)' },
    <Country>{ value: 'PN', display: 'Pitcairn' },
    <Country>{ value: 'PL', display: 'Poland' },
    <Country>{ value: 'PT', display: 'Portugal' },
    <Country>{ value: 'PR', display: 'Puerto Rico' },
    <Country>{ value: 'QA', display: 'Qatar' },
    <Country>{ value: 'MK', display: 'Republic of North Macedonia' },
    <Country>{ value: 'RO', display: 'Romania' },
    <Country>{ value: 'RU', display: 'Russian Federation (the)' },
    <Country>{ value: 'RW', display: 'Rwanda' },
    <Country>{ value: 'RE', display: 'Réunion' },
    <Country>{ value: 'BL', display: 'Saint Barthélemy' },
    <Country>{
        value: 'SH',
        display: 'Saint Helena, Ascension and Tristan da Cunha',
    },
    <Country>{ value: 'KN', display: 'Saint Kitts and Nevis' },
    <Country>{ value: 'LC', display: 'Saint Lucia' },
    <Country>{ value: 'MF', display: 'Saint Martin (French part)' },
    <Country>{ value: 'PM', display: 'Saint Pierre and Miquelon' },
    <Country>{ value: 'VC', display: 'Saint Vincent and the Grenadines' },
    <Country>{ value: 'WS', display: 'Samoa' },
    <Country>{ value: 'SM', display: 'San Marino' },
    <Country>{ value: 'ST', display: 'Sao Tome and Principe' },
    <Country>{ value: 'SA', display: 'Saudi Arabia' },
    <Country>{ value: 'SN', display: 'Senegal' },
    <Country>{ value: 'RS', display: 'Serbia' },
    <Country>{ value: 'SC', display: 'Seychelles' },
    <Country>{ value: 'SL', display: 'Sierra Leone' },
    <Country>{ value: 'SG', display: 'Singapore' },
    <Country>{ value: 'SX', display: 'Sint Maarten (Dutch part)' },
    <Country>{ value: 'SK', display: 'Slovakia' },
    <Country>{ value: 'SI', display: 'Slovenia' },
    <Country>{ value: 'SB', display: 'Solomon Islands' },
    <Country>{ value: 'SO', display: 'Somalia' },
    <Country>{ value: 'ZA', display: 'South Africa' },
    <Country>{
        value: 'GS',
        display: 'South Georgia and the South Sandwich Islands',
    },
    <Country>{ value: 'SS', display: 'South Sudan' },
    <Country>{ value: 'ES', display: 'Spain' },
    <Country>{ value: 'LK', display: 'Sri Lanka' },
    <Country>{ value: 'SD', display: 'Sudan (the)' },
    <Country>{ value: 'SR', display: 'Suriname' },
    <Country>{ value: 'SJ', display: 'Svalbard and Jan Mayen' },
    <Country>{ value: 'SE', display: 'Sweden' },
    <Country>{ value: 'CH', display: 'Switzerland' },
    <Country>{ value: 'SY', display: 'Syrian Arab Republic' },
    <Country>{ value: 'TW', display: 'Taiwan (Province of China)' },
    <Country>{ value: 'TJ', display: 'Tajikistan' },
    <Country>{ value: 'TZ', display: 'Tanzania, United Republic of' },
    <Country>{ value: 'TH', display: 'Thailand' },
    <Country>{ value: 'TL', display: 'Timor-Leste' },
    <Country>{ value: 'TG', display: 'Togo' },
    <Country>{ value: 'TK', display: 'Tokelau' },
    <Country>{ value: 'TO', display: 'Tonga' },
    <Country>{ value: 'TT', display: 'Trinidad and Tobago' },
    <Country>{ value: 'TN', display: 'Tunisia' },
    <Country>{ value: 'TR', display: 'Turkey' },
    <Country>{ value: 'TM', display: 'Turkmenistan' },
    <Country>{ value: 'TC', display: 'Turks and Caicos Islands (the)' },
    <Country>{ value: 'TV', display: 'Tuvalu' },
    <Country>{ value: 'UG', display: 'Uganda' },
    <Country>{ value: 'UA', display: 'Ukraine' },
    <Country>{ value: 'AE', display: 'United Arab Emirates (the)' },
    <Country>{
        value: 'GB',
        display: 'United Kingdom of Great Britain and Northern Ireland (the)',
    },
    <Country>{
        value: 'UM',
        display: 'United States Minor Outlying Islands (the)',
    },
    <Country>{ value: 'US', display: 'United States of America (the)' },
    <Country>{ value: 'UY', display: 'Uruguay' },
    <Country>{ value: 'UZ', display: 'Uzbekistan' },
    <Country>{ value: 'VU', display: 'Vanuatu' },
    <Country>{ value: 'VE', display: 'Venezuela (Bolivarian Republic of)' },
    <Country>{ value: 'VN', display: 'Viet Nam' },
    <Country>{ value: 'VG', display: 'Virgin Islands (British)' },
    <Country>{ value: 'VI', display: 'Virgin Islands (U.S.)' },
    <Country>{ value: 'WF', display: 'Wallis and Futuna' },
    <Country>{ value: 'EH', display: 'Western Sahara' },
    <Country>{ value: 'YE', display: 'Yemen' },
    <Country>{ value: 'ZM', display: 'Zambia' },
    <Country>{ value: 'ZW', display: 'Zimbabwe' },
    <Country>{ value: 'AX', display: 'Åland Islands' },
];
