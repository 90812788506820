import { HttpClient, json } from 'aurelia-fetch-client';
import { inject, autoinject, bindable } from 'aurelia-framework';
import { Menu, Action, Separator } from 'components/menu/stuff';
import { EventAggregator } from 'aurelia-event-aggregator';
import './menu-container.scss';

@autoinject
export class MenuContainer {
    @bindable menu: Menu = new Menu();
    @bindable x: number = 0;
    @bindable y: number = 0;
    eventAggregator: EventAggregator;
    show: boolean = false;

    constructor(eventAggregator: EventAggregator) {
        this.eventAggregator = eventAggregator;
    }

    attached() {
        this.eventAggregator.subscribe('menu', (message) => {
            let $event: MouseEvent = message.event;

            $event.stopPropagation();
            this.menu = message.menu;
            this.show = true;
            this.x = $event.clientX;
            this.y = $event.clientY;
            document.addEventListener('click', this.method);
        });
    }

    method = () => {
        this.show = false;
        document.removeEventListener('click', this.method);
    };
}
