import { html, ReactiveControllerHost } from 'lit';

import '../pli/pli-dialog';
import { when } from 'lit/directives/when.js';

interface ErrorDialogProps {
    title?: string;
    bodyText?: string;
    buttonLabel?: string;
    errorMessage?: string;
}

export class ErrorDialogController implements ErrorDialogProps {
    host: ReactiveControllerHost;
    title: string;
    bodyText: string;
    buttonLabel: string;
    errorMessage: string;

    open: HTMLDialogElement['open'] = false;

    constructor(host: ReactiveControllerHost) {
        this.host = host;
        host.addController(this);
    }

    hostConnected() {}

    async showError({
        title = 'Something went wrong',
        bodyText = 'There was an error while performing this operation. Please try again. If the errror persists please contact our support.',
        buttonLabel = 'OK',
        errorMessage,
    }: ErrorDialogProps = {}) {
        this.title = title;
        this.bodyText = bodyText;
        this.open = true;
        this.buttonLabel = buttonLabel;
        this.errorMessage = errorMessage;

        document.body.classList.add('dialog-open');

        this.host.requestUpdate();
        await this.host.updateComplete;
    }

    renderOnError() {
        const onClose = () => {
            this.open = false;
            this.host.requestUpdate();
            document.body.classList.remove('dialog-open');
        };

        return html`${when(
            this.open,
            () =>
                html`<pli-dialog .open="${this.open}" @close="${onClose}">
                    <pli-dialog-content>
                        <pli-icon slot="error-icon" name="exclamation-circle" size="48"></pli-icon>

                        <pli-text variant="h3">${this.title}</pli-text><pli-text as="p">${this.bodyText}</pli-text>

                        ${when(this.errorMessage, () => html`<pli-text as="p">${this.errorMessage}</pli-text>`)}
                    </pli-dialog-content>
                    <pli-dialog-close-button variant="primary" slot="primary-button">
                        ${this.buttonLabel}
                    </pli-dialog-close-button>
                </pli-dialog>`,
        )}`;
    }
}
