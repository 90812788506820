import { bindable, customElement } from 'aurelia-framework';

@customElement('collapse')
export class Collapse {
    element: any;
    @bindable visible: boolean = true;

    @bindable titleText: string;

    toggle() {
        this.visible = !this.visible;
    }
}
