import { bindable, bindingMode } from 'aurelia-framework';

const risks = {
    Low: {
        color: 'low',
    },
    Medium: {
        color: 'medium',
    },
    High: {
        color: 'high',
    },
    Critical: {
        color: 'critical',
    },
};

export class RiskLevel {
    @bindable({ defaultBindingMode: bindingMode.oneTime }) risk: string;

    color: string;

    riskChanged(value: string) {
        if (value === undefined || value === null) {
            return;
        }

        let status = risks[value];

        if (status) {
            this.color = status.color;
        }
    }
}
