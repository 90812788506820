import { UserModel } from 'user-model';
import { Profile } from 'users/profile/profile';

export class CurrentUser {
    async get(): Promise<UserModel> {
        let result = new UserModel();

        result.userId = Profile.payload.sub;
        result.firstName = Profile.payload.given_name;
        result.lastName = Profile.payload.family_name;
        result.fullName = Profile.payload.name;
        return result;
    }

    decode(input: string): string {
        return decodeURIComponent(escape(input));
    }
}
