import { bindable, containerless } from 'aurelia-framework';
import iconPath from 'bootstrap-icons/bootstrap-icons.svg';

@containerless()
export class Icon {
    iconUrl: string;

    @bindable sprite: string;
    @bindable css: string;
    @bindable size: number = 24;

    attached() {
        this.iconUrl = iconPath + '#' + this.sprite;
    }

    spriteChanged(value: string) {
        this.iconUrl = iconPath + '#' + value;
    }
}
