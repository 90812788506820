import { bindable, bindingMode, customElement } from 'aurelia-framework';

@customElement('copy-text-element')
export class CopyTextElement {
    @bindable text: string;
    showIcon: boolean;

    copyToClipboard() {
        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
            return navigator.clipboard.writeText(this.text);
        }

        return Promise.reject('The Clipboard API is not available.');
    }

    omouseover(e) {
        e.stopPropagation();
        if (this.showIcon) {
            return;
        }
        this.showIcon = true;

        e.preventDefault();
    }

    omouseout() {
        this.showIcon = false;
    }
}
