import { ReactiveControllerHost } from 'lit';
import { StorageHandler } from '../storage-handler';
import '../pli/pli-icon';
import { Order, OrderSchema } from '../tms-client';

export class StorageOrderController {
    private _sortOrders = [...OrderSchema.enum];
    private _field: string | null = null;
    private _order: Order = this._sortOrders[0];
    private host: ReactiveControllerHost;
    private _sortOrderKey: string;

    constructor(host: ReactiveControllerHost, sortOrderKey: string) {
        this.host = host;
        host.addController(this);
        this._sortOrderKey = sortOrderKey;
    }

    get sortField(): string {
        return this._field;
    }

    set sortField(value: string) {
        this._field = value;
        this.host.requestUpdate();
    }

    get sortOrder(): Order {
        return this._order;
    }

    set sortOrder(value: Order) {
        this._order = value;
        this.host.requestUpdate();
    }

    private _setOrderFromStorage() {
        const storedOrderItem = StorageHandler.getFromStorage(this._sortOrderKey);

        if (storedOrderItem !== null) {
            this._order = storedOrderItem.sortOrder;
            this._field = storedOrderItem.field;
        }
    }

    hostConnected() {
        this._setOrderFromStorage();
    }

    hostDisconnected() {}
}
