import { bindable, bindingMode, autoinject } from 'aurelia-framework';
import { CommentModel } from '../../comment-model';
import { UserModel } from 'user-model';
import { HttpClient, json } from 'aurelia-fetch-client';
import { ConfirmService } from 'components/confirmation/confirm-service';
import { CurrentUser } from 'components/current-user/current-user';

@autoinject
export class CommentsList {
    comments: CommentModel[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay }) editable: boolean = true;
    @bindable id: string;
    @bindable context: string;
    @bindable disabled: boolean = false;
    text: string = '';
    profile: UserModel = new UserModel();
    httpClient: HttpClient;
    confirmService: ConfirmService;
    editing: CommentModel = null;
    replying: CommentModel = null;
    currentUser: CurrentUser;

    constructor(httpClient: HttpClient, confirmService: ConfirmService, currentUser: CurrentUser) {
        this.httpClient = httpClient;
        this.confirmService = confirmService;
        this.currentUser = currentUser;
    }

    async attached() {
        this.profile = await this.currentUser.get();

        let response = await this.httpClient.get(`${this.context}/${this.id}/comments`);
        let data = await response.json();

        this.comments = <CommentModel[]>data.list;
    }

    async add() {
        let response = await this.httpClient.post(`${this.context}/${this.id}/comments`, json({ comment: this.text }));
        let id = (await response.json()).commentId;
        let model = new CommentModel();

        model.commentId = id;
        model.text = this.text;
        model.createdTime = new Date();
        model.createdBy = this.profile;

        this.comments.push(model);
        this.text = '';
        this.editing = null;
    }

    async addReply(parent: CommentModel) {
        let response = await this.httpClient.post(
            `${this.context}/${this.id}/comments`,
            json({ parentId: parent.commentId, comment: this.text }),
        );
        let id = (await response.json()).commentId;
        let model = new CommentModel();

        model.commentId = id;
        model.text = this.text;
        model.createdTime = new Date();
        model.createdBy = this.profile;

        parent.children.push(model);
        this.text = '';
        this.replying = null;
    }

    async edit(comment: CommentModel) {
        this.text = comment.text;
        this.replying = null;
        this.editing = comment;
    }

    async cancel(comment: CommentModel) {
        this.replying = null;
        this.editing = null;
        this.text = '';
    }

    async save(comment: CommentModel) {
        await this.httpClient.put(
            `${this.context}/${this.id}/comments/${comment.commentId}`,
            json({ comment: this.text }),
        );

        this.editing.text = this.text;
        this.editing = null;
        this.text = null;
    }

    async reply(comment: CommentModel) {
        this.text = null;
        this.replying = comment;
        this.editing = null;
    }

    async post(comment: CommentModel) {
        this.replying = comment;
        this.editing = null;
    }

    remove(comment: CommentModel) {
        this.confirmService.confirm(
            'Delete comment?',
            'Are you sure you want to delete the comment. This action cannot be undone.',
            async () => {
                await this.httpClient.delete(`${this.context}/${this.id}/comments/${comment.commentId}`, '{}');

                let index = this.comments.findIndex((x) => x.commentId == comment.commentId);

                this.comments.splice(index, 1);
            },
        );
    }

    removeReply(parent: CommentModel, comment: CommentModel) {
        this.confirmService.confirm(
            'Delete comment?',
            'Are you sure you want to delete the comment. This action cannot be undone.',
            async () => {
                await this.httpClient.delete(`${this.context}/${this.id}/comments/${comment.commentId}`, '{}');

                let index = parent.children.findIndex((x) => x == comment);

                parent.children.splice(index, 1);
            },
        );
    }
}
