import { html, ReactiveControllerHost } from 'lit';
import '../pli/pli-toast';
import { PliToastOrdered, PliToastProps } from '../pli/pli-toast';
import { when } from 'lit/directives/when.js';

type ToastInvocationProps = PliToastProps & {
    lifespan?: number;
};

export class ToastController {
    counter = 0;
    host: ReactiveControllerHost;
    title: string;
    bodyText: string;
    toasts: PliToastOrdered[] = [];

    constructor(host: ReactiveControllerHost) {
        this.host = host;
        this.host.addController(this);
    }

    log(toast: PliToastOrdered) {
        this.toasts = [toast, ...this.toasts];
        this.host.requestUpdate();

        if (!toast.lifespan) return;

        this.hideOnLifespanEnd(toast);
    }

    hideOnLifespanEnd(toast: PliToastOrdered) {
        setTimeout(() => this.hide(toast.id), toast.lifespan);
    }

    success({ title = '', bodyText = 'The operation was successful', lifespan }: ToastInvocationProps = {}) {
        this.log({ title, bodyText, id: this.counter++, lifespan });
    }

    hide(id: PliToastOrdered['id']) {
        this.toasts = [...this.toasts.filter((item) => item.id !== id)];
        this.host.requestUpdate();
    }

    get hasToasts() {
        return this.toasts.length > 0;
    }

    render() {
        return when(
            this.hasToasts,
            () =>
                html`<pli-toast-container
                    @toast-remove="${(event) => this.hide(event.detail)}"
                    .items="${this.toasts}"
                ></pli-toast-container>`,
        );
    }

    hostConnected() {}
}
