import { ReactiveControllerHost } from 'lit';

export class AsyncIntervalController {
    _asyncIntervals: { id: number; run: boolean }[] = [];
    _intervalIndex: number | null = null;
    host: ReactiveControllerHost;

    constructor(host: ReactiveControllerHost) {
        this.host = host;
        host.addController(this);
    }

    get currentIntervalIndex() {
        return this._intervalIndex;
    }

    _runAsyncInterval = async (callback: () => Promise<void>, interval: number, intervalIndex: number) => {
        await callback();
        if (this._asyncIntervals[intervalIndex].run) {
            this._asyncIntervals[intervalIndex].id = window.setTimeout(
                () => this._runAsyncInterval(callback, interval, intervalIndex),
                interval,
            );
        }
    };

    setAsyncInterval = (callback: () => Promise<void>, interval: number) => {
        const isValidInput = Boolean(callback) && typeof callback === 'function';

        if (isValidInput) {
            const intervalIndex = this._asyncIntervals.length;
            this._asyncIntervals.push({ run: true, id: 0 });
            this._runAsyncInterval(callback, interval, intervalIndex);
            this._intervalIndex = intervalIndex;
            return intervalIndex;
        } else {
            throw new Error('Callback must be a function');
        }
    };

    clearAsyncInterval = (intervalIndex: number) => {
        const hasIntervalToClear =
            Boolean(this._asyncIntervals[intervalIndex]) && this._asyncIntervals[intervalIndex].run;
        if (hasIntervalToClear) {
            window.clearTimeout(this._asyncIntervals[intervalIndex].id);
            this._asyncIntervals[intervalIndex].run = false;
        }
    };

    hostConnected() {}
}
