import { ContextProvider, createContext } from '@lit/context';
import { DialogService } from 'aurelia-dialog';
export { DialogService } from 'aurelia-dialog';

export const dialogServiceContext = createContext<DialogService>('dialogService');
export const createDialogServiceProvider = (dialogService: DialogService) =>
    new ContextProvider(document.body, {
        context: dialogServiceContext,
        initialValue: dialogService,
    });
