import { DialogController } from 'aurelia-dialog';
import { HttpClient, json } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import './transaction-dialog.scss';

@autoinject
export class TransactionDialog {
    controller: DialogController;
    httpClient: HttpClient;
    model;
    props: Property[] = [];
    transactionPartyProps: Property[] = [];

    constructor(controller: DialogController, httpClient: HttpClient) {
        this.controller = controller;
        this.httpClient = httpClient;
    }

    activate(model) {
        this.model = model;
        this.props = this.model.properties;
        this.transactionPartyProps = this.model.transactionParty?.properties;
    }
}

class Property {}
