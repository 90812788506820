import { DialogController, DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';

export class ConfirmDialogModel {
    title: string;
    message: string;

    constructor(title: string, message: string) {
        this.message = message;
        this.title = title;
    }
}

@autoinject
export class ConfirmDialog {
    controller: DialogController;
    model: ConfirmDialogModel;

    constructor(controller: DialogController) {
        this.controller = controller;
    }

    activate(model: ConfirmDialogModel) {
        this.model = model;
    }

    async ok() {
        this.controller.ok(this.model);
    }
}
