import { html, LitElement, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { NumbersValueConverter } from 'numbers';
import { PropertyResult } from 'tms-client';
import { getFormattedDate } from 'utils/datetime-formatter';
import '../../../pli/pli-icon';

@customElement('property-view')
class PropertyView extends LitElement {
    @property({ type: Object })
    prop: PropertyResult;
    render() {
        const { to, from, type, value } = this.prop;
        const templateMap: Record<PropertyResult['type'], () => TemplateResult> = {
            Boolean: () => html`<pli-icon size="${24}" name="${Boolean(type) ? 'check' : 'dash'}"></pli-icon>`,
            Number: () => html`<div>${new NumbersValueConverter().toView(value)}</div>`,
            String: () => html`<div>${value}</div>`,
            Date: () => html`<div>${getFormattedDate(new Date(value))}</div>`,
            NumberRange: () => html`<div>${from} - ${to}</div>`,
            DateRange: () => html`<div>${getFormattedDate(new Date(from))} - ${getFormattedDate(new Date(to))}</div>`,
        };
        return when(templateMap[type], () => html`${templateMap[type]()}`);
    }
}
