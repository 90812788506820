import { bindable, bindingMode } from 'aurelia-framework';

export interface IPagedController {
    changePage(page: number);
}

export class Pager {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) page: number;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) totalPages: number = 0;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    controller: IPagedController;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) total: number = 0;

    siblingCount: number = 1;
    boundaryCount: number = 1;
    items: any[] = [];

    async previous() {
        if (this.page == 1) {
            return;
        }

        await this.changePage(this.page - 1, this.page);
    }

    async next() {
        if (this.page >= this.totalPages) {
            return;
        }

        await this.changePage(this.page + 1, this.page);
    }

    jump(page: number) {
        this.changePage(page, this.page);
    }

    private updatePages() {
        const range = (start, end) => {
            const length = end - start + 1;
            return Array.from({ length }, (_, i) => start + i);
        };

        const startPages = range(1, Math.min(this.boundaryCount, this.totalPages));
        const endPages = range(
            Math.max(this.totalPages - this.boundaryCount + 1, this.boundaryCount + 1),
            this.totalPages,
        );

        const siblingsStart = Math.max(
            Math.min(
                // Natural start
                this.page - this.siblingCount,
                // Lower boundary when page is high
                this.totalPages - this.boundaryCount - this.siblingCount * 2 - 1,
            ),
            // Greater than startPages
            this.boundaryCount + 2,
        );

        const siblingsEnd = Math.min(
            Math.max(
                // Natural end
                this.page + this.siblingCount,
                // Upper boundary when page is low
                this.boundaryCount + this.siblingCount * 2 + 2,
            ),
            // Less than endPages
            endPages.length > 0 ? endPages[0] - 2 : this.totalPages - 1,
        );

        this.items = [
            ...startPages,

            // Start ellipsis
            ...(siblingsStart > this.boundaryCount + 2
                ? [null]
                : this.boundaryCount + 1 < this.totalPages - this.boundaryCount
                  ? [this.boundaryCount + 1]
                  : []),

            // Sibling pages
            ...range(siblingsStart, siblingsEnd),

            // End ellipsis

            ...(siblingsEnd < this.totalPages - this.boundaryCount - 1
                ? [null]
                : this.totalPages - this.boundaryCount > this.boundaryCount
                  ? [this.totalPages - this.boundaryCount]
                  : []),

            ...endPages,
        ];
    }

    async changePage(value: number, oldValue: number) {
        if (this.controller) {
            await this.controller.changePage(value);
        }

        this.page = value;
        this.updatePages();
    }

    totalPagesChanged(value: number, oldValue: number) {
        this.updatePages();
    }
}
