export class Companies {
    companies: Company[] = [
        new Company('d042b649-ae7b-484b-9593-c189ff4119cd', 'Goto 11', false, true),
        new Company('cc2152e2-cd3d-4ed6-a2c0-99a5d9824af3', 'Plisec', true, false),
    ];
}

class Company {
    constructor(
        public id: string,
        public name: string,
        public isSanction: boolean,
        public isSip: boolean,
    ) {}
}
