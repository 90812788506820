import { autoinject } from 'aurelia-framework';
import { DialogController, DialogService } from 'aurelia-dialog';
import { ConfirmDialog, ConfirmDialogModel } from './confirm-dialog';

@autoinject
export class ConfirmService {
    dialogService: DialogService;

    constructor(dialogService: DialogService) {
        this.dialogService = dialogService;
    }

    confirm(title: string, message: string, onConfirmation: any) {
        let model: ConfirmDialogModel = new ConfirmDialogModel(title, message);

        this.dialogService
            .open({ viewModel: ConfirmDialog, model: model, lock: false })
            .whenClosed(async (response) => {
                if (!response.wasCancelled) {
                    onConfirmation();
                }
            });
    }
}
