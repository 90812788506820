import { HttpClient, json } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { bindable, bindingMode } from 'aurelia-framework';

import { Genders } from '../../gender';
import { Country, Countries } from '../../country';
import { CustomerModel } from '../../customer-model';

@autoinject
export class CustomerEditDetails {
    httpClient: HttpClient;
    editing: boolean = false;

    @bindable({ defaultBindingMode: bindingMode.twoWay }) customer: CustomerModel;

    @bindable editable: boolean = true;
    @bindable saved;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    genders: string[] = Genders;
    countries: Country[] = Countries;

    onEdit() {
        this.editing = true;
    }

    async onSave() {
        try {
            await this.httpClient.put(
                `Customers/${this.customer.customerId}`,
                json({
                    firstName: this.customer.firstName,
                    lastName: this.customer.lastName,
                    identification: this.customer.identification,
                    personalInformation: this.customer.personalInformation,
                    bankInformation: this.customer.bankInformation,
                    incomeInformation: this.customer.incomeInformation,
                }),
            );
        } catch (error) {
            alert('onSave: ' + error.message);
        } finally {
            this.editing = false;

            await this.saved();
        }
    }
}
