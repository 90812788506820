import ColorHash from 'color-hash';
import { bindable, bindingMode } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';

@autoinject
export class ColorLabel {
    @bindable text: string = 'N/A';
    @bindable hash: string;
    colorHash: ColorHash;
    bgcolor: string = '0';

    constructor() {
        this.colorHash = new ColorHash();
    }

    textChanged(value: string, old: string) {
        this.update();
    }

    hashChanged(value: string, old: string) {
        this.update();
    }

    update() {
        let hash = this.hash || this.text;

        this.bgcolor = this.colorHash.hex(hash);
    }

    attached() {
        this.update();
    }
}
