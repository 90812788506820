export abstract class MenuBase {
    abstract template(): string;
}

export class Menu extends MenuBase {
    items: MenuBase[] = [];

    constructor(public name: string = 'N/A') {
        super();
    }

    Add(item: MenuBase) {
        this.items.push(item);
    }

    template(): string {
        return './template-submenu.html';
    }
}

export class Action extends MenuBase {
    action: () => any;

    constructor(
        public name: string,
        action: () => any,
    ) {
        super();
        this.action = action;
    }

    template(): string {
        return './template-action.html';
    }
}

export class Separator extends MenuBase {
    template(): string {
        return './template-separator.html';
    }
}
