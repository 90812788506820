import { customElement, bindable } from 'aurelia-framework';
import { NavigationInstruction, Router } from 'aurelia-router';

@customElement('breadcrumb')
export class Breadcrumb {
    public parts: BreadcrumbPart[] = [];

    @bindable title: string;

    constructor(private router: Router) {}

    attached() {
        this.refresh();
    }

    private refresh(): void {
        const parentInstructions = this.getParentInstructions(this.router.currentInstruction);

        //console.dir(this.router.currentInstruction);
        this.parts = parentInstructions
            .slice(0, parentInstructions.length - 1)
            .concat(this.router.currentInstruction.getAllInstructions())
            .filter((instruction) => instruction.config.title)
            .map((instruction: NavigationInstruction) => this.getBreadcrumbPart(instruction));

        //console.dir(this.parts);
    }

    private getBreadcrumbPart(instruction: NavigationInstruction): BreadcrumbPart {
        const { childRoute, ...params } = instruction.params;

        let url = instruction.router.generate(instruction.config.name, params, {
            absolute: false,
        });
        return {
            title: instruction.config.title,
            routeName: instruction.config.name,
            params: params,
            url: url,
        };
    }

    private navigateToRoute(instruction: BreadcrumbPart): void {
        this.router.navigateToRoute(instruction.routeName, instruction.params);
    }

    private getParentInstructions(instruction: NavigationInstruction): NavigationInstruction[] {
        return instruction.parentInstruction
            ? this.getParentInstructions(instruction.parentInstruction).concat([instruction])
            : [instruction];
    }
}

export interface BreadcrumbPart {
    title: string;
    routeName: string;
    params: any;
    url: string;
}
