// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `menu-container {
  position: fixed;
  z-index: 2000;
}
menu-container > ul.dropdown-menu {
  position: relative;
  display: inline-block;
}
menu-container ul.dropdown-menu {
  position: absolute;
  user-select: none;
}
menu-container ul.dropdown-menu ul.dropdown-menu {
  display: none;
}
menu-container ul.dropdown-menu ul.dropdown-menu.show {
  display: inline-block;
}
menu-container .dropdown-text {
  position: relative;
}
menu-container .dropdown-text > div {
  display: flex;
}
menu-container .dropdown-text > div .text {
  flex-grow: 1;
}
menu-container .dropdown-text > div .caret {
  flex-shrink: 1;
}
menu-container .dropdown-text:hover > ul.dropdown-menu {
  display: inline-block;
}
menu-container .dropdown-text ul.dropdown-menu {
  position: absolute;
  top: 3px;
  right: 10px;
  transform: translateX(100%);
}
menu-container .dropdown-text.dropdown-item.active, menu-container .dropdown-text.dropdown-item:active {
  color: var(--bs-dropdown-link-hover-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-hover-bg);
}`, "",{"version":3,"sources":["webpack://./src/components/menu/menu-container.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,aAAA;AACJ;AACI;EACI,kBAAA;EACA,qBAAA;AACR;AAEI;EACI,kBAAA;EACA,iBAAA;AAAR;AAEQ;EACI,aAAA;AAAZ;AAEY;EACI,qBAAA;AAAhB;AAKI;EACI,kBAAA;AAHR;AAKQ;EACI,aAAA;AAHZ;AAKY;EACI,YAAA;AAHhB;AAMY;EACI,cAAA;AAJhB;AASY;EACI,qBAAA;AAPhB;AAWQ;EACI,kBAAA;EACA,QAAA;EACA,WAAA;EACA,2BAAA;AATZ;AAYQ;EAEI,0CAAA;EACA,qBAAA;EACA,kDAAA;AAXZ","sourcesContent":["menu-container {\n    position: fixed;\n    z-index: 2000;\n\n    > ul.dropdown-menu {\n        position: relative;\n        display: inline-block;\n    }\n\n    ul.dropdown-menu {\n        position: absolute;\n        user-select: none;\n\n        ul.dropdown-menu {\n            display: none;\n\n            &.show {\n                display: inline-block;\n            }\n        }\n    }\n\n    .dropdown-text {\n        position: relative;\n\n        > div {\n            display: flex;\n\n            .text {\n                flex-grow: 1;\n            }\n\n            .caret {\n                flex-shrink: 1;\n            }\n        }\n\n        &:hover {\n            > ul.dropdown-menu {\n                display: inline-block;\n            }\n        }\n\n        ul.dropdown-menu {\n            position: absolute;\n            top: 3px;\n            right: 10px;\n            transform: translateX(100%);\n        }\n\n        &.dropdown-item.active,\n        &.dropdown-item:active {\n            color: var(--bs-dropdown-link-hover-color);\n            text-decoration: none;\n            background-color: var(--bs-dropdown-link-hover-bg);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
