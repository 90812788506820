import { ReactiveControllerHost } from 'lit';
import { z, ZodRawShape } from 'zod';

const issueSchema = z.object({
    name: z.string().min(1),
    message: z.string().min(1),
});
export type Issue = z.infer<typeof issueSchema>;

export class ValidationController<T extends ZodRawShape> {
    _issues: Issue[] = [];
    host: ReactiveControllerHost;
    schema: z.ZodObject<T>;

    constructor(host: ReactiveControllerHost, schema: z.ZodObject<T>) {
        this.host = host;
        this.schema = schema;
        host.addController(this);
    }

    private _mapErrors(error: z.ZodError<{ [x: string]: any }>): Issue[] {
        return error.issues.map((issue) => ({
            name: String(issue.path[0]),
            message: issue.message,
        }));
    }

    private _setIssues(issues: Issue[]) {
        this._issues = [...issues];
    }

    get issues() {
        return this._issues;
    }

    get isValid() {
        return this._issues.length === 0;
    }

    isInvalidField = (field: keyof T): boolean => Boolean(this._issues.find((issue) => issue.name === field));

    parsePartial(input: unknown): { isValid: boolean } {
        const key = Object.keys(input)[0];
        const { shape } = this.schema;

        const partialSchema = z.object({
            [String(key)]: shape[key],
        });

        const { success, error } = partialSchema.safeParse(input);

        if (success) {
            this._setIssues(this._issues.filter((issue) => issue.name !== key));
        }

        if (error) {
            const addedIssues = this._mapErrors(error);
            this._setIssues([...this._issues, ...addedIssues]);
        }

        this.host.requestUpdate();

        return { isValid: success };
    }

    parse(input: unknown): { isValid: boolean } {
        const { error, success } = this.schema.safeParse(input);

        if (success) {
            this._setIssues([]);
        }

        if (error) {
            const issues = this._mapErrors(error);
            this._setIssues(issues);
        }

        this.host.requestUpdate();

        return { isValid: success };
    }

    hostConnected() {}
    hostDisconnected() {}
}
